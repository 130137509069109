import { differenceInSeconds } from 'date-fns';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';






// ===============================================================================================================
// ===============================================================================================================
// ===============================================================================================================



export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export function debounce(func, wait, immediate) {
  console.log("debounce.ogre");
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) {
        console.log("debounce.ogre", "timed api");
        func.apply(context, args);
      }
    }, wait);
    if (immediate && !timeout) {
      console.log("debounce.ogre", "untimed api");
      func.apply(context, args);
    }
  };
}

export const debounce_simple = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};


export function isMobile() {
  if (window) return window.matchMedia(`(max-width: 767px)`).matches;

  return false;
}

export function isMdScreen() {
  if (window) return window.matchMedia(`(max-width: 1199px)`).matches;

  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}



// ===============================================================================================================
// ===============================================================================================================
// ===============================================================================================================



function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
}

function getDeltaMinutes(gmtTimestamp, now) {  //Date.now()
  var localDate = convertUTCDateToLocalDate(new Date(gmtTimestamp));
  var local_epoch = localDate.valueOf();  // milliseconds
  var local_delta = now.now() - local_epoch;   // Date.now() = milliseconds

  var minutes = local_delta / 1000 / 60;
  return minutes;
}

export function getDeltaTime(theTime, now) {
  try {

    let delta = now.getTime() - theTime.getTime();
    var hours = delta / 1000 / 60 / 60;
    var days = Math.floor(hours / 24);
    hours = hours - (24 * days);
    var time_delta = "";
    if (days == 0) {
      if (hours < 1.0) {
        var minutes = delta / 1000 / 60;
        if (minutes.toFixed(0) == "1") {
          time_delta = "1 minute";
        } else {
          time_delta = minutes.toFixed(0) + " minutes";
        }
      } else {
        time_delta = hours.toFixed(1) + " hours";
      }
    } else {
      time_delta = days.toFixed(0) + " days " + hours.toFixed(0) + " hours";
      if (hours.toFixed(0) == "1")
        time_delta = time_delta.replace("hours", "hour");
    }

    return { timeString: time_delta, deltaSeconds: delta / 1000 };

  } catch (e) {
    return "";
  }
}

export function formatTime(ts_in) {
  try {
    if (ts_in == undefined)
      return "";

    let theTime = new Date(ts_in);

    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let yyyy = theTime.getFullYear();
    let mm = (1 + theTime.getMonth()).toString().padStart(2, '0');
    let dd = theTime.getDate().toString().padStart(2, '0');

    let t_yyyy = now.getFullYear();
    let t_mm = (1 + now.getMonth()).toString().padStart(2, '0');
    let t_dd = now.getDate().toString().padStart(2, '0');

    let y_yyyy = yesterday.getFullYear();
    let y_mm = (1 + yesterday.getMonth()).toString().padStart(2, '0');
    let y_dd = yesterday.getDate().toString().padStart(2, '0');

    let day = "";
    if (yyyy == t_yyyy && mm == t_mm && dd == t_dd) {
      day = "Today";
    } else if (yyyy == y_yyyy && mm == y_mm && dd == y_dd) {
      day = "Yesterday";
    } else {
      day = mm + "/" + dd;
    }
    let deltaTimes = getDeltaTime(theTime, now);
    try {
      return {
        timeString: deltaTimes.timeString + " ago -  " + day + " " + theTime.toLocaleTimeString(),
        deltaSeconds: deltaTimes.deltaSeconds
      };
    } catch (e) {
      console.log(e);
    }
    return {
      timeString: 'unknown',
      deltaSeconds: 0
    };

    //return theTime.toISOString();
  } catch (e) {
    console.log(e);
  }
  return { timeString: ts_in, deltaSeconds: 9999999 };
}

export function formatTime2(ts_in) {
  if (ts_in) {
    let { timeString, deltaSeconds } = formatTime(ts_in);
    return timeString;
  }
  return "";
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function sleep(delay = 0) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}
//await sleep(3000); // For demo purposes.

export function adjustColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function getEnvironment(hsInfo) {

  //return hsInfo["environment"] || "";
  let environment = "";
  if (hsInfo && "environment" in hsInfo) {
    environment = hsInfo["environment"]
  }

  return environment;

}

function get_deploy_location() {
  const currentUrl = window.location.href;
  if (currentUrl.indexOf("rcp.care") > -1) {
    //console.log("get_deploy_location:k8s");
    return "k8s";
  }

  //console.log("get_deploy_location:local");
  return "local";
}

export function wsUrl() {
  const currentUrl = window.location.href;
  let url = new URL(currentUrl)

  if (get_deploy_location() == "k8s") {
    console.log("wss://" + url.hostname + "/apiws/");
    return "wss://" + url.hostname + "/apiws/";
  } else {
    //console.log("ws://localhost:3001/apiws/");
    //return "ws://localhost:3001/apiws/";
    console.log("ws://mdm.armware.com:3001/apiws/");
    return "ws://mdm.armware.com:3001/apiws/";
  }

}

export function apiUrl(path) {
  const currentUrl = window.location.href;
  let url = new URL(currentUrl)
  // 'http://mdm.armware.com:3001/api/auth/profile'

  if (get_deploy_location() == "k8s") {
    console.log("apiUrl:" + url.protocol + "//" + url.hostname + path);
    return url.protocol + "//" + url.hostname + path;
  } else {
    console.log("apiUrl:" + url.protocol + "//" + url.hostname + ":3001" + path);
    return url.protocol + "//" + url.hostname + ":3001" + path;
  }

}

export const hubsupportalApi = axios.create({
  baseURL: apiUrl("/api/"), // "http://localhost:3000/api/",
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
  timeout: 30000, // 30 seconds timeout
});

// Store the original get and post methods
const originalGet = hubsupportalApi.get;
const originalPost = hubsupportalApi.post;

// Override the get method
const cache = new Map();
hubsupportalApi.get = function (...args) {
  const [url, config = {}] = args;
  const { cache_timeout, totalRetries = 5, ...restConfig } = config; // Extract totalRetries with a default value

  const cacheKey = JSON.stringify({ url, config: restConfig });

  if (cache_timeout !== undefined) {
    const cachedItem = cache.get(cacheKey);
    if (cachedItem && (Date.now() - cachedItem.timestamp) < cache_timeout * 1000) {
      console.log('woody', 'Returning cached GET request:', url);
      return Promise.resolve(cachedItem.response);
    }
  }

  const timeoutId = startRequest('GET ' + url);
  console.log('woody', 'Starting GET request:', url);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const makeRequest = (retries, delayMs) => {
    return originalGet.apply(this, [url, restConfig])
      .then(response => {
        console.log('woody', 'Completed GET request:', url);
        endRequest(timeoutId, 'GET ' + url);

        if (cache_timeout !== undefined) {
          console.log('woody', 'GET result added to cache:', url);
          cache.set(cacheKey, {
            response: response,
            timestamp: Date.now()
          });
        }

        return response;
      })
      .catch(async (error) => {
        if (retries > 0) {
          console.log('woody', `Retrying GET request (${totalRetries - retries + 1}/${totalRetries}):`, url);
          await delay(delayMs); // Wait before retrying
          return makeRequest(retries - 1, delayMs + 1000 ); // Increase delay linearly
          return makeRequest(retries - 1, delayMs * 2); // Increase delay exponentially
        }

        console.log('woody', 'Error in GET request:', url, error);
        endRequest(timeoutId, 'GET ' + url);
        throw error;
      });
  };

  return makeRequest(totalRetries, 1000); // Start with the configured retries and delay
};


// Override the post method
hubsupportalApi.post = function(...args) {
  const timeoutId = startRequest('POST ' + args[0]);
  console.log('woody', 'Starting POST request:', args[0]);

  return originalPost.apply(this, args)
    .then(response => {
      console.log('woody', 'Completed POST request:', args[0]);
      endRequest(timeoutId, 'POST ' + args[0]);
      return response;
    })
    .catch(error => {
      console.log('woody', 'Error in POST request:', args[0], error);
      endRequest(timeoutId, 'POST ' + args[0]);
      throw error;
    });
};
hubsupportalApi.interceptors.request.use(
  async (config) => {
    console.log("hubsupportalApi.ogre", config.url);
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      console.log("interceptor::request access_token", access_token);
      config.headers["Authorization"] = `Bearer ${access_token}`;
      config.headers["x-access-token"] = `${access_token}`;
    }
    const refresh_token = localStorage.getItem("refresh_token");
    if (refresh_token) {
      config.headers["x-refresh-token"] = `${refresh_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const refreshToken = async () => {

  let delayedRetry = false;
  const url = apiUrl("/auth/refresh");
  //const wth = apiUrl("/auth/wth");
  try {
    //const resp = await hubsupportalApi.get("auth/refresh");
    const headers = {
      'x-access-token': localStorage.getItem("access_token"),
      'x-refresh-token': localStorage.getItem("refresh_token")
    }


    //const resp_wth = await axios.get(wth, { withCredentials: true, headers: headers });


    //const resp = await axios.post(url, {}, { withCredentials: true, headers: headers });
    const resp = await axios.get(url, { withCredentials: true, headers: headers });
    console.log("refreshToken", url, resp);
    return resp.data;
  } catch (e) {
    console.log("API Error", url, e);
    if (e.response.status == 401) { // refresh failed
      console.log("login debug 4 - refreshToken - 401 ", url);
      window.location.replace(apiUrl("/auth/login"));
    }
    if (e.response.status == 409) { // refresh in process
      delayedRetry = true;
    }
    if (e.response.status == 410) { // no refresh_token
      console.log("login debug 4 - refreshToken - 410 ", url);
      window.location.replace(apiUrl("/auth/login"));
    }
  }

  if (delayedRetry) {
    try {
      const sleep = ms => new Promise(r => setTimeout(r, ms));
      await sleep(5000)
      const resp = await refreshToken();
      return resp;
    } catch (e) {
    }

  }
};

hubsupportalApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {

    console.log("interceptor::response error", error);

    const originalRequest = error.config;
    if (error.response) {
      if ((error.response.status === 401 || error.response.status === 403)
        && !originalRequest._retry
        && !originalRequest.url.endsWith("refresh")) {
        try {
          originalRequest._retry = true;

          const resp = await refreshToken();

          localStorage.setItem("access_token", resp.access_token);
          localStorage.setItem("refresh_token", resp.refresh_token);

          hubsupportalApi.defaults.withCredentials = true

          hubsupportalApi.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.access_token}`;
          return hubsupportalApi(originalRequest);
        } catch (e) {
          console.log(e);
          console.log("login debug 5 - interceptor - exception", apiUrl("/auth/login"));
          window.location.replace(apiUrl("/auth/login"));
        }
      }
    }
    return Promise.reject(error);
  }
);

export function allowAction(action, current_hub_type) {

  console.log("pookie2 : ", current_hub_type);

  if (action.category == 'linux_hub') {
    if (current_hub_type == 'android')
      return false;
  } else if (action.category == 'android_hub') {
    if (current_hub_type == 'linux')
      return false;
  } else if (action.category == 'any_hub') {
  } else {
    return false;
  }

  return true;

}

export function format_milliseconds(milliseconds) {
  try {
    var total_hours = parseInt(milliseconds) / 1000 / 60 / 60;
    var days = Math.floor(total_hours / 24);
    var hours = Math.floor(total_hours - (24 * days));
    var minutes = (total_hours - (24 * days) - hours) * 60;
    var res = "";
    if (days == 1) {
      res = res + days.toFixed(0) + " day ";
    } else if (days > 0) {
      res = res + days.toFixed(0) + " days ";
    }
    if (hours == 1) {
      res = res + hours.toFixed(0) + " hour ";
    } else if (hours > 0) {
      res = res + hours.toFixed(0) + " hours ";
    }
    if (minutes == 1) {
      res = res + minutes.toFixed(1) + " minute ";
    } else if (minutes > 0) {
      res = res + minutes.toFixed(1) + " minutes ";
    }
    return res;
    /*
    if ( days == 0 ) {
        return hours.toFixed(1) + " hours"; 
    } else {
        return days.toFixed(0) + " days " + hours.toFixed(0) + " hours"; 
    }
    */
  } catch (e) {

  }

  return String(milliseconds);
}

/*
let activeRequests = 0;
let timeoutIds = new Set();

export function startRequest() {
  activeRequests++;
  const timeoutId = setTimeout(() => {
    console.warn('Request timed out');
    endRequest();
  }, 30000);
  timeoutIds.add(timeoutId);
  return timeoutId;
}

export function endRequest(timeoutId) {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutIds.delete(timeoutId);
  }
  activeRequests = Math.max(0, activeRequests - 1);
}
*/

let activeRequests = 0;
let timeoutIds = new Set();
let listeners = new Set();

export function startRequest(which) {
  activeRequests++;
  const timeoutId = (() => {
    const id = setTimeout(() => {
      console.warn('Request timed out');
      console.log("buzz","timeout", which, timeoutId, activeRequests);
      endRequest(id, which);
    }, 30000);
    return id;
  })();
  console.log("buzz","start", which, timeoutId, activeRequests);
  timeoutIds.add(timeoutId);
  notifyListeners();
  return timeoutId;
}

export function endRequest(timeoutId, which) {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutIds.delete(timeoutId);
  }
  activeRequests = Math.max(0, activeRequests - 1);
  notifyListeners();
  console.log("buzz","end", which, timeoutId, activeRequests);
}

function notifyListeners() {
  listeners.forEach(listener => listener(activeRequests > 0));
}

export function useIsProcessing() {
  const [isProcessing, setIsProcessing] = useState(activeRequests > 0);

  useEffect(() => {
    const listener = (processing) => {
      setIsProcessing(processing);
    };

    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  }, []);

  return isProcessing;
}

export const useRefreshOnProcessingChange = (callback) => {
  const isProcessing = useIsProcessing();

  useEffect(() => {
    if (!isProcessing) {
      callback();
    }
  }, [isProcessing, callback]);
};
